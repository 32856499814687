<template>
    <article
        @mouseover="showCardActions = true"
        @mouseleave="showCardActions = false"
    >
        <div
            class="image"
            @click="
                handleClick({
                    name: 'Produs',
                    params: { slug: product.slug },
                })
            "
        >
            <v-lazy-image
                loading="lazy"
                :src="product.thumbnail"
                :alt="product.name"
                width="240"
                height="160"
            />
        </div>
        <card-actions
            v-if="showCardActions || isMobile"
            :is-mobile="isMobile"
            :compare-data="compareObject"
            :can-compare="!categoryHasSubCategories"
            :product="product"
        />
        <div class="ticket">
            <div
                v-if="product.discount_percentage"
                class="promo"
            >
                -{{ product.discount_percentage }}%
            </div>
            <div
                v-if="product.new"
                class="new"
            >
                {{ $t('page.product.newSticker') }}
            </div>
        </div>
        <div
            class="title"
            @click="
                handleClick({
                    name: 'Produs',
                    params: { slug: product.slug },
                })
            "
        >
            <p class="sbold">
                {{ product.name }}
            </p>
        </div>
        <rating-stars
            :rating="{ value: product.rating, counter: product.rating_counter }"
            location="product"
        />
        <span
            v-if="product.price"
            class="price"
        ><del
             v-if="product.discount_percentage"
         ><span
             v-dompurify-html="formatPrice(product.price)"
             class="big"
         /></del>
            <div
                v-if="!product.discount_percentage"
                v-dompurify-html="formatPrice(product.price)"
            />
            <div
                v-else
                v-dompurify-html="formatPrice(product.current_price)"
            />
        </span>
        <div class="add-item d-flex">
            <add-to-cart
                :stock="product.stock_furnizor"
                :product="cartProduct"
                :is-icon="isIcon"
            />
            <add-to-wishlist
                v-if="isMobile"
                :product="product"
                location="listing"
            />
        </div>
    </article>
</template>

<script>
    import VLazyImage from 'v-lazy-image/v2';
    import { mapActions, mapGetters } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    import AddToCart from '../controls/product/AddToCart';
    import AddToWishlist from '../controls/product/AddToWishlist';
    import RatingStars from './RatingStars';

    const CardActions = () =>
        import(
            /* webpackChunkName: "product-card-actions" */ '../controls/product/CardActions'
        );

    export default {
        name: 'ProductCard',
        components: {
            AddToWishlist,
            RatingStars,
            CardActions,
            AddToCart,
            VLazyImage,
        },
        props: {
            product: {
                type: Object,
                required: true,
            },
            categoryHasSubCategories: {
                type: Boolean,
                default: true,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            searchKey: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                showCardActions: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            compareObject() {
                let compare = {};
                compare[this.product.category] = {
                    productCode: this.product.code,
                    thumbnail: this.product.thumbnail,
                };
                return compare;
            },
            cartProduct() {
                let product = {
                    id: this.product.id,
                    thumbnail: this.product.thumbnail,
                    name: this.product.name,
                    price: this.product.price,
                    discount_percentage: this.product.discount_percentage,
                    current_price: this.product.current_price,
                };
                return product;
            },
            stockLabel() {
                return this.product.stock_furnizor > 0
                    ? this.$t('page.product.stock.exist')
                    : this.$t('page.product.stock.unavailable');
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
            isIcon() {
                return this.$screen.breakpoint == 'md' ? 'icon' : '';
            },
        },
        methods: {
            ...mapActions('search', ['storeSearch']),
            formatPrice(price) {
                return splitedPrice(price);
            },
            handleClick(path) {
                if (this.isSearch && this.isLogged) {
                    this.storeSearch({
                        productId: this.product.id,
                        searchKey: this.searchKey,
                    });
                }
                this.$router.push(path).catch(() => {
                //
                });
            },
        },
    };
</script>

<style scoped lang="scss">
article {
    background-color: $white;
    padding: 12px;
    box-shadow: 0 0 0 1px $gray-light;
    border-radius: 8px;
    transition-timing-function: ease-in;
    transition: 300ms;
    position: relative;
    @include media-breakpoint-up(md) {
        &:hover {
            box-shadow: 0px 4px 16px $gray-light;
            .actions {
                opacity: 0.9;
            }
        }
    }
    .image {
        cursor: pointer;
        aspect-ratio: 3 / 2;
        width: 100%;
        margin-bottom: 16px;
        img {
            pointer-events: none;
            object-fit: contain;
        }
    }
    .title {
        margin-bottom: 8px;
        cursor: pointer;
        p {
            font-size: 14px;
        }
    }
    .ticket {
        position: absolute;
        top: 20px;
        right: -8px;
        > div {
            color: white;
            padding: 8px 10px;
            border-radius: 4px 0px 0px 4px;
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            position: relative;
            text-align: center;
            &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                right: 0;
                width: 0;
                height: 0;
                border-top: 0px solid transparent;
                border-left: 8px solid $gray-dark;
                border-bottom: 4px solid transparent;
            }
            &.promo {
                background-color: $primary;
                margin-bottom: 4px;
            }
            &.new {
                background-color: $secondary;
            }
        }
    }

    .add-item .btn {
        margin-top: 12px;
        @include media-breakpoint-between(xl, xxl) {
            font-size: 12px;
        }
    }
}
</style>
