var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{on:{"mouseover":function($event){_vm.showCardActions = true},"mouseleave":function($event){_vm.showCardActions = false}}},[_c('div',{staticClass:"image",on:{"click":function($event){return _vm.handleClick({
                name: 'Produs',
                params: { slug: _vm.product.slug },
            })}}},[_c('v-lazy-image',{attrs:{"loading":"lazy","src":_vm.product.thumbnail,"alt":_vm.product.name,"width":"240","height":"160"}})],1),(_vm.showCardActions || _vm.isMobile)?_c('card-actions',{attrs:{"is-mobile":_vm.isMobile,"compare-data":_vm.compareObject,"can-compare":!_vm.categoryHasSubCategories,"product":_vm.product}}):_vm._e(),_c('div',{staticClass:"ticket"},[(_vm.product.discount_percentage)?_c('div',{staticClass:"promo"},[_vm._v(" -"+_vm._s(_vm.product.discount_percentage)+"% ")]):_vm._e(),(_vm.product.new)?_c('div',{staticClass:"new"},[_vm._v(" "+_vm._s(_vm.$t('page.product.newSticker'))+" ")]):_vm._e()]),_c('div',{staticClass:"title",on:{"click":function($event){return _vm.handleClick({
                name: 'Produs',
                params: { slug: _vm.product.slug },
            })}}},[_c('p',{staticClass:"sbold"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),_c('rating-stars',{attrs:{"rating":{ value: _vm.product.rating, counter: _vm.product.rating_counter },"location":"product"}}),(_vm.product.price)?_c('span',{staticClass:"price"},[(_vm.product.discount_percentage)?_c('del',[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatPrice(_vm.product.price)),expression:"formatPrice(product.price)"}],staticClass:"big"})]):_vm._e(),(!_vm.product.discount_percentage)?_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatPrice(_vm.product.price)),expression:"formatPrice(product.price)"}]}):_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.formatPrice(_vm.product.current_price)),expression:"formatPrice(product.current_price)"}]})]):_vm._e(),_c('div',{staticClass:"add-item d-flex"},[_c('add-to-cart',{attrs:{"stock":_vm.product.stock_furnizor,"product":_vm.cartProduct,"is-icon":_vm.isIcon}}),(_vm.isMobile)?_c('add-to-wishlist',{attrs:{"product":_vm.product,"location":"listing"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }