<template>
    <div class="product-listing slider-wrapper">
        <Flicking
            ref="flicking"
            :options="{
                duration: 500,
                bound: true,
                autoResize: true,
                align: 'prev',
            }"
            @changed="updateButtonsState"
        >
            <div
                v-for="product in products"
                :key="`product-${products.indexOf(product)}`"
                class="panel slide"
            >
                <product-card :product="product" />
            </div>
        </Flicking>
        <b-button
            v-if="navigationButtons"
            class="icon stroke go-prev"
            :disabled="prevDisabled"
            variant="text"
            @click="prevSlide"
        >
            <chevron-left-icon />
        </b-button>
        <b-button
            v-if="navigationButtons"
            class="icon stroke go-next"
            :disabled="nextDisabled"
            variant="text"
            @click="nextSlide"
        >
            <chevron-right-icon />
        </b-button>
    </div>
</template>

<script>
    import { Flicking } from '@egjs/vue-flicking';
    import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';

    import ProductCard from '@/components/product/ProductCard';
    export default {
        name: 'ProductListingSlider',
        components: {
            ChevronLeftIcon,
            ChevronRightIcon,
            ProductCard,
            Flicking: Flicking,
        },
        data() {
            return {
                currentIndex: 0,
                totalSlides: 0,
                screenWidth: 0,
                perPage: [
                    [320, 1.5],
                    [420, 2.5],
                    [576, 3.5],
                    [1024, 4],
                    [1200, 5],
                ],
                panelsToShow: 1.0,
            };
        },
        computed: {
            navigationButtons() {
                if (this.screenWidth < 1024) {
                    return false;
                }
                return true;
            },
            prevDisabled() {
                return this.currentIndex < 1;
            },
            nextDisabled() {
                return this.currentIndex + this.panelsToShow >= this.totalSlides;
            },
        },
        props: {
            products: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        methods: {
            updateButtonsState() {
                this.currentIndex = this.$refs.flicking.index;
            },
            updatePanelsToShow() {
                this.screenWidth = window.innerWidth;

                for (let i = this.perPage.length - 1; i >= 0; i--) {
                    if (this.screenWidth >= this.perPage[i][0]) {
                        this.panelsToShow = this.perPage[i][1];
                        break;
                    }
                }

                this.updateFlickingOptions();
                this.totalSlides = this.products.length;
            },
            updateFlickingOptions() {
                const flicking = this.$refs.flicking;

                if (flicking) {
                    flicking.panels.forEach((panel) => {
                        panel.element.style.width = `${100 / this.panelsToShow}%`;
                    });

                    flicking.resize();
                }
            },
            prevSlide() {
                let index = this.$refs.flicking.index;
                let prevIndex = index - this.panelsToShow;
                if (prevIndex < 1) {
                    prevIndex = 0;
                }
                this.$refs.flicking.moveTo(prevIndex);
            },
            nextSlide() {
                let index = this.$refs.flicking.index;
                let nextIndex = index + this.panelsToShow;

                if (nextIndex > this.totalSlides) {
                    nextIndex = this.totalSlides;
                }
                this.$refs.flicking.moveTo(nextIndex);
            },
        },
        mounted() {
            this.updatePanelsToShow();
            window.addEventListener('resize', this.updatePanelsToShow);
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
    };
</script>

<style lang="css">
@import '~@egjs/vue-flicking/dist/flicking.css';
</style>

<style lang="scss">
.product-listing {
    &.slider-wrapper {
        @include media-breakpoint-up(xl) {
            margin-left: -12px;
        }
        @include media-breakpoint-down(lg) {
            margin-left: -12px;
        }
        .flicking-viewport {
            .panel {
                padding: 25px 12px;
                .add-item {
                    display: none !important;
                }
                article {
                    .image {
                        aspect-ratio: 3 / 2;
                        img {
                            width: 100%;
                            height: 160px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .go-prev,
        .go-next {
            top: 50%;
            position: absolute;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            &.disabled {
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
                @include media-breakpoint-between(lg, xl) {
                    opacity: 0.5;
                }
            }
        }
        .go-prev {
            left: -5px;
            transform: translateY(-50%) translateX(-100%);
             @include media-breakpoint-between(lg, xl) {
                     left: 40px;
                }
        }
        .go-next {
            right: -5px;
            transform: translateY(-50%) translateX(100%);
             @include media-breakpoint-between(lg, xl) {
                   right: 40px;
                }
        }
    }
}
</style>
